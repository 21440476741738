<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2 mt-2"
        fab
        dark
        x-small
        color="#f5222d"
        v-bind="attrs"
        v-on="on"
        @click="$emit('archive-clicked')"
      >
        <v-icon> fas fa-file-archive </v-icon>
      </v-btn>
    </template>
    <span>Архивирай</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DeleteButton",
};
</script>

<style scoped></style>
