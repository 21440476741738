<template>
  <v-dialog :value="value" @input="handleDialogInput" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Сигурни ли сте, че искате да архивирате тази маршрутна карта? Tова
        дейтсвие не може да бъде отменено!
      </v-card-title>

      <v-card-text>
        <ErrorMessages :errorMessages="localErrorMessages" />

        Бележка:
        <textarea v-model="note" class="form-control"></textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text>
          <span
            class="btn btn-danger btn-block"
            @click="$emit('cancel-clicked')"
          >
            ОТКАЖИ
          </span>
        </v-btn>
        <v-btn text>
          <span class="btn btn-success btn-block" @click="handleArchiveClicked">
            АРХИВИРАНЕ
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from "@/components/ErrorMessages";
import { DELETE_ROUTEMAP } from "@/store/routemaps.module";

export default {
  components: {
    ErrorMessages,
  },

  props: {
    value: {
      required: true,
      type: Boolean,
    },

    archivingId: {
      required: true,
    },

    archivingName: {
      required: true,
    },

    errorMessages: {
      default: () => {},
    },
  },

  data() {
    return {
      note: "",
      localErrorMessages: {},
    };
  },

  watch: {
    value() {
      this.note = "";
      this.localErrorMessages = {};
    },

    errorMessages() {
      this.localErrorMessages = this.errorMessages;
    },
  },

  methods: {
    handleArchiveClicked() {
      if (!this.note) {
        this.localErrorMessages = {
          0: ["Полето бележка е задължително"],
        };

        return;
      }

      let vm = this;

      vm.$confirm({
        title: `Сигурен ли си, че искаш да архивираш ${vm.archivingName}?`,
        content: "Tова дейтсвие не може да бъде отменено!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.archive();
        },
        onCancel() {},
      });
    },

    archive() {
      let vm = this;

      vm.$store
        .dispatch(DELETE_ROUTEMAP, { id: vm.archivingId, note: vm.note })
        .then((data) => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message,
          });

          vm.$emit("archived");

          vm.localErrorMessages = [];
        })
        .catch((errors) => {
          if (errors.data.errors.note) {
            vm.localErrorMessages = Object.values(errors.data.errors);
          }
        });
    },

    handleDialogInput(value) {
      this.note = "";
      this.localErrorMessages = {};

      this.$emit("input", value);
    },
  },
};
</script>
